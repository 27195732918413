import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import axios from 'axios';
import moment from "moment";
import { Link } from "react-router-dom";

const Blog = () => {
  const [getBlog, setBlog] = useState([])

  useEffect(() => {
    let timer= setTimeout(function () {
      document.querySelector(".loader-wrapper").style = "display: none";
    }, 2000);
    return () =>{ clearTimeout(timer)}
  }, []);
  const [bgImg, setBgImg]= useState({
    backgroundImage:`url(${process.env.PUBLIC_URL}/assets/images/team-img-bg.png)`
  });

  const color= localStorage.getItem("color");
  
  useEffect(async()=>{
   
    const _getBlog = async () => {
      try{
      await axios.get(`https://evarkadasimapp.com.tr:2222/getBlogEv/select/`)
       .then(res => {
        setBlog(res.data)
       })
     }
     catch (error) {
       
       console.log("errorAS" + error)
     }
  }

  await _getBlog()

    if(color === "color-1")
     setBgImg( {
      backgroundImage:`url(${process.env.PUBLIC_URL}/assets/images/team-img-bg.png)`
    });
    else if(color === "color-2")
    setBgImg({ backgroundImage:`url(${process.env.PUBLIC_URL}/assets/images/color/color-2/team-img-bg.png)`})
    else 
    setBgImg({backgroundImage:`url(${process.env.PUBLIC_URL}/assets/images/color/color-3/team-img-bg.png)`})
  },[color]);

  //Slick slider Options
  const options = {
    arrows: false,
    accessibility: false,
    infinite: true,
    dots: true,
    dotClass: ".blog-carousel",
    margin: 30,
    speed: 300,
    slidesToShow: 2,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
          infinite: true,
        },
      }
    ],
  };



  // Dynamic Blog Data Loop
  let DataList = getBlog.map((val, i) => {
    return (
      <div className="blog-item" key={i}>
        <div className="blog-block" style={bgImg} >
          <div className="blog-box">
            <div className="overflow-hidden">
              <Link  state= {{ blogContent: val }} to={{
    pathname: `/blog-details/${encodeURIComponent(val.Blog_Title)}`,
   
  }}>
                <img src={'https://megasubdomain.stechomeyazilim.info/' + val.Blog_Image} className="img-fluid" alt="" />
              </Link>
            </div>
          </div>
        </div>
        <div className="blog-text">
          <h6>{moment(val.Created_DateTime).format('DD/MM/YYYY')}</h6>
          <Link state= {{ blogContent: val }} to={{
    pathname: `/blog-details/${encodeURIComponent(val.Blog_Title)}`,
  
  }}> 
            <h3>{val.Blog_Title}</h3>
          </Link>
          <h5>
            Ev Arkadaşım tarafından
          </h5>
        </div>
      </div>
    );
  });

  return (
    <section id="blog" className="blog">
      <div className="about-decor">
        <div className="about-circle1">
          <img src={`${process.env.PUBLIC_URL}/assets/images/team1.png`} className="img-fluid" alt="team1" />
        </div>
        <div className="about-circle2">
          <img src={`${process.env.PUBLIC_URL}/assets/images//main-banner1.png`} className="img-fluid" alt="main-banner1" />
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-sm-12">
            <h2 className="title">
              Son <span> Blog Yazıları</span>
            </h2>
          </div>
          <div className="col-sm-12">
            <Slider
              className="blog-carousel owl-carousel owl-theme rounded-dots slick-margin"
              {...options}
            >
              {DataList}
            </Slider>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Blog;
