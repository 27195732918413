import "./App.css";
import BlogDetails from "./blog/BlogDetails";
import BlogLeftSide from "./blog/BlogLeftSide";
import BlogLeftSidebar from "./blog/BlogLeftSidebar";
import BlogList from "./blog/BlogList";
import BlogRightSide from "./blog/BlogRightSide";
import BlogRightSidebar from "./blog/BlogRightSidebar";
//import DemoApp from "./demo-page/index";
import PageNotFound from "./Pages/404";
import ComingSoon from "./Pages/ComingSoon";
import Download from "./Pages/Download";
import FAQ from "./Pages/FAQ";
import ForgetPwd from "./Pages/ForgetPwd";
import Review from "./Pages/Review";
import SignIn from "./Pages/SignIn";
import SignUp from "./Pages/SignUp";
import ThankYou from "./Pages/ThankYou";
import React,{ useEffect} from "react";
import HomeOne from "./HomeOne";
//import HomeTwo from "./HomeTwo";
//import HomeThree from "./HomeThree";
import { Route, Routes } from "react-router-dom";  // Switch yerine Routes kullanılıyor
import ColorPicker from "./Services/ColorPicker";

function App() {
  useEffect(() => {
    let timer= setTimeout(function () {
      document.querySelector(".loader-wrapper").style = "display: none";
    }, 2000);
    return () =>{ clearTimeout(timer)}
  }, []);
 localStorage.setItem("color","color-1");
  return (
    <div className="App">
    <Routes>
      <Route path={`${process.env.PUBLIC_URL}/`} element={<HomeOne />} />
 {/**   <Route path={`${process.env.PUBLIC_URL}/home-two`} element={<HomeTwo />} />
      <Route path={`${process.env.PUBLIC_URL}/home-three`} element={<HomeThree />} /> */}  
      <Route path={`${process.env.PUBLIC_URL}/blog-details`} element={<BlogDetails />} />
      <Route path={`${process.env.PUBLIC_URL}/blog-list`} element={<BlogList />} />
      <Route path={`${process.env.PUBLIC_URL}/blog-left-sidebar`} element={<BlogLeftSidebar />} />
      <Route path={`${process.env.PUBLIC_URL}/blog-right-sidebar`} element={<BlogRightSidebar />} />
      <Route path={`${process.env.PUBLIC_URL}/blog-leftside`} element={<BlogLeftSide />} />
      <Route path={`${process.env.PUBLIC_URL}/blog-rightside`} element={<BlogRightSide />} />
      <Route path={`${process.env.PUBLIC_URL}/sign-in`} element={<SignIn />} />
      <Route path={`${process.env.PUBLIC_URL}/sign-up`} element={<SignUp />} />
      <Route path={`${process.env.PUBLIC_URL}/forget-password`} element={<ForgetPwd />} />
      <Route path={`${process.env.PUBLIC_URL}/thank-you`} element={<ThankYou />} />
      <Route path={`${process.env.PUBLIC_URL}/review`} element={<Review />} />
      <Route path={`${process.env.PUBLIC_URL}/404`} element={<PageNotFound />} />
      <Route path={`${process.env.PUBLIC_URL}/faq`} element={<FAQ />} />
      <Route path={`${process.env.PUBLIC_URL}/download`} element={<Download />} />
      <Route path={`${process.env.PUBLIC_URL}/coming-soon`} element={<ComingSoon />} />
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  </div>
  );
}

export default App;
